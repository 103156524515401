$fontFamily: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica-Neue,
  Helvetica, "BBAlpha Sans", "Segoe UI", Arial, "Noto Sans", -apple-system,
  BlinkMacSystemFont, sans-serif;
$latoFamily: "Lato", Verdana, Geneva, -apple-system, BlinkMacSystemFont,
  sans-serif;

$black: #0f0f0f;
$white: #fff;
$darkBlue: #2a3c68; // Heading text, same as primaryBack
$blue: #4fa2d9;
$lightBlue: #8ed8f8;
$offBlue: #def3fd;
$offWhite: #f1f2f2;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Incrementally lighten a color in a more effective way than with darken()
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - Percentage of white in the returned color
/// @return {Color} - The lightened color
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

/// Incrementally darken a color in a more effective way than with darken()
/// @access public
/// @param {Color} $color - Color to shade
/// @param {Number} $percentage - Percentage of black in the returned color
/// @return {Color} - The darkened color
@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

/// Helper function mix black with a given opacity level
@function black($opacity) {
  @return rgba(#000, $opacity);
}

/// Helper function mix white with a given opacity level
@function white($opacity) {
  @return rgba(#fff, $opacity);
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List} Computed values for the bottom-shadow
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(#000, nth(0.24 0.23 0.23 0.22 0.22, $depth));

  @return 0 $primary-offset $blur $color;
}

// Start of helper mixins

// Takes a given property and a corresponding value and pairs it with a space
// separated list of any vendor prefixes desired ("moz webkit" for "-moz- and
// -webkit-"). If no list of prefixes is given then the webkit, -moz, -ms, and
// -o prefixes are applied to the selector.
@mixin prefix($property, $value, $vendors: moz ms o webkit) {
  @if $vendors {
    @each $vendor in $vendors {
      #{'-' + $vendor + '-' + $property}: $value;
    }
  }
  #{$property}: $value;
}

@mixin border-radius($radius: 5px) {
  background-clip: padding-box; // Stops bg color from leaking outside the border
  @if $radius == "circle" {
    border-radius: 50%;
  } @else {
    border-radius: $radius;
  }
}

@mixin box-sizing($box-model: border-box) {
  box-sizing: $box-model;
}

// Use for all transforms except for translates in short comma separated notation
@mixin transform($transformation) {
  @include prefix(transform, $transformation, ms webkit);
}

@mixin animate($animationValues) {
  @include prefix(animation, $animationValues, webkit);
}

@mixin frames($animationName, $property, $from, $to) {
  @-webkit-keyframes #{$animationName} {
    0% {
      -webkit-#{$property}: $from;
    }

    100% {
      -webkit-#{$property}: $to;
    }
  }
  @keyframes #{$animationName} {
    0% {
      #{$property}: $from;
    }

    100% {
      #{$property}: $to;
    }
  }
}

/// Appllies all the necessary flex properties, used on wrappers containing the flexed items
@mixin flex-wrapper {
  display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
  display: -ms-flexbox; // TWEENER - IE 10
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($alignment: center) {
  @include prefix(align-items, $alignment, ms webkit);
}

@mixin justify-content($alignment: center) {
  @include prefix(justify-content, $alignment, ms webkit);
}

@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}

@mixin vert-align($pos: 50%) {
  @if $pos == middle or $pos == mid or $pos == center or $pos == c or $pos == 50
  {
    $pos: 50%;
  }
  position: relative;
  top: $pos;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin absolute-center {
  margin: 0 auto;
  @include vert-align;
  vertical-align: middle;
}

@mixin bounce-in(
  $direction: top,
  $timing: 1.1s,
  $size: 15rem,
  $delay: null,
  $infinite: null,
  $timing-func: ease-in,
  $height: $size
) {
  // Values for top $direction default
  $dir-val1: translateY(-500px);
  $dir-val2: translateY(0);
  $dir-val3: translateY(-65px);
  $dir-val4: translateY(-28px);
  $dir-val5: translateY(-8px);

  @if $infinite {
    $infinite: infinite alternate;
  }

  @if $direction == "bottom" or $direction == "b" or $direction == "down" {
    $dir-val1: translateY(500px);
    $dir-val2: translateY(0);
    $dir-val3: translateY(65px);
    $dir-val4: translateY(28px);
    $dir-val5: translateY(8px);
  } @else if $direction == "right" or $direction == "r" {
    $dir-val1: translateX(600px);
    $dir-val2: translateX(0);
    $dir-val3: translateX(68px);
    $dir-val4: translateX(32px);
    $dir-val5: translateX(8px);
  } @else if $direction == "left" or $direction == "l" {
    $dir-val1: translateX(-600px);
    $dir-val2: translateX(0);
    $dir-val3: translateX(-68px);
    $dir-val4: translateX(-32px);
    $dir-val5: translateX(-8px);
  } @else if $direction == "forward" or $direction == "fwd" or $direction == "f"
  {
    $dir-val1: scale(0);
    $dir-val2: scale(1);
    $dir-val3: scale(0.7);
    $dir-val4: scale(0.84);
    $dir-val5: scale(0.95);
  } @else if
    $direction ==
    "backward" or
    $direction ==
    "bckwrd" or
    $direction ==
    "back" or
    $direction ==
    "bck" or
    $direction ==
    "b"
  {
    $dir-val1: scale(7);
    $dir-val2: scale(1);
    $dir-val3: scale(1.5);
    $dir-val4: scale(1.24);
    $dir-val5: scale(1.04);
  } @else if $direction != "top" and $direction != "t" and $direction != "up" {
    @error '`#{$direction}` is not a valid direction for the ' +
    'bounce-in mixin.';
  }
  -webkit-animation: bounce-in #{$timing} #{$timing-func} #{$infinite} #{$delay}
    both;
  animation: bounce-in #{$timing} #{$timing-func} #{$infinite} #{$delay} both;
  height: $height;
  width: $size;

  @if $direction ==
    "top" or
    $direction ==
    "t" or
    $direction ==
    "up" or
    $direction ==
    "bottom" or
    $direction ==
    "b" or
    $direction ==
    "down" or
    $direction ==
    "right" or
    $direction ==
    "r" or
    $direction ==
    "left" or
    $direction ==
    "l"
  {
    @at-root {
      @-webkit-keyframes bounce-in {
        0% {
          -webkit-transform: #{$dir-val1};
          transform: #{$dir-val1};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          opacity: 0;
        }

        38% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          opacity: 1;
        }

        55% {
          -webkit-transform: #{$dir-val3};
          transform: #{$dir-val3};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        72%,
        90%,
        100% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
        }

        81% {
          -webkit-transform: #{$dir-val4};
          transform: #{$dir-val4};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        95% {
          -webkit-transform: #{$dir-val5};
          transform: #{$dir-val5};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }
      }
      @keyframes bounce-in {
        0% {
          -webkit-transform: #{$dir-val1};
          transform: #{$dir-val1};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          opacity: 0;
        }

        38% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          opacity: 1;
        }

        55% {
          -webkit-transform: #{$dir-val3};
          transform: #{$dir-val3};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        72%,
        90%,
        100% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
        }

        81% {
          -webkit-transform: #{$dir-val4};
          transform: #{$dir-val4};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        95% {
          -webkit-transform: #{$dir-val5};
          transform: #{$dir-val5};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }
      }
    }
  } @else if
    $direction ==
    "forward" or
    $direction ==
    "fwd" or
    $direction ==
    "f" or
    $direction ==
    "backward" or
    $direction ==
    "bckwrd" or
    $direction ==
    "back" or
    $direction ==
    "bck" or
    $direction ==
    "b"
  {
    @at-root {
      @-webkit-keyframes bounce-in {
        0% {
          -webkit-transform: #{$dir-val1};
          transform: #{$dir-val1};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          opacity: 0;
        }

        38% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          opacity: 1;
        }

        55% {
          -webkit-transform: #{$dir-val3};
          transform: #{$dir-val3};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        72%,
        89%,
        100% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
        }

        81% {
          -webkit-transform: #{$dir-val4};
          transform: #{$dir-val4};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        95% {
          -webkit-transform: #{$dir-val5};
          transform: #{$dir-val5};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }
      }
      @keyframes bounce-in {
        0% {
          -webkit-transform: #{$dir-val1};
          transform: #{$dir-val1};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          opacity: 0;
        }

        38% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          opacity: 1;
        }

        55% {
          -webkit-transform: #{$dir-val3};
          -ms-transform: #{$dir-val3};
          transform: #{$dir-val3};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        72%,
        89%,
        100% {
          -webkit-transform: #{$dir-val2};
          transform: #{$dir-val2};
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
        }

        81% {
          -webkit-transform: #{$dir-val4};
          transform: #{$dir-val4};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }

        95% {
          -webkit-transform: #{$dir-val5};
          transform: #{$dir-val5};
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
        }
      }
    }
  }
}

@mixin flex-center {
  @include align-items;
  @include flex-wrapper;
  @include justify-content;
}

// Loading Screen
.wrapper-main {
  @include box-sizing;
  border-width: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  vertical-align: baseline;
  width: 100%;
}

.loading-screen {
  @include size(100%);
  background-color: rgba($darkBlue, 0.7);
  cursor: wait;
  left: 0;
  overflow: visible;
  padding-top: 11%;
  position: fixed;
  top: 0;
  z-index: 9999;

  &__display-area {
    background-color: rgba(#111, 0.7);
    margin: 0 auto;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }

  &__msg {
    color: $blue;
    font: small-caps 400 1.25em $latoFamily;
    letter-spacing: 1.2px;
    text-align: center;
  }

  &__spinner {
    animation: 2s linear infinite colorSpinner, 1.3s ease-in;
    @include border-radius(circle);
    @include size(40px);
    @include box-sizing;
    margin: 0 auto;

    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    -webkit-background-size: 100% 50%;
    -moz-background-size: 100% 50%;
    background-size: 100% 50%;
    background-image: -webkit-linear-gradient(left, $blue 0%, $darkBlue 100%),
      -webkit-linear-gradient(left, $blue 0%, $darkBlue 100%);
    background-image: -moz-linear-gradient(left, $blue 0%, $darkBlue 100%),
      -moz-linear-gradient(left, $blue 0%, $darkBlue 100%);
    background-image: linear-gradient(to right, $blue 0%, $darkBlue 100%),
      linear-gradient(to right, $blue 0%, $darkBlue 100%);
  }
}

@keyframes bounce-in {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72%,
  90%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes colorSpinner {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes colorSpinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
