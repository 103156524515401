.error-text {
  color: rgb(236, 12, 12);
}

.text-center {
  text-align: center;
}

.success-text {
  color: rgb(3, 216, 21);
}
