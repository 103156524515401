@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap");

@import "./header";
@import "./app";
@import "./authWindow";
@import "./loader";
@import "./dashboard";

body {
  margin: 0;
}
