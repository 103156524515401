.drawer-container {
  position: unset !important;
  top: 64px !important;

  .drawer-contents {
    min-width: 280px;
    max-width: 80vw;
  }

  .MuiDrawer-paper {
    top: 64px !important;
  }
}

.pagination-container {
  display: table;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
